.divider {
  display: flex;
  align-items: center;
  text-align: center;
  /* width: 433px; */
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid #dcdee0;
}

.divider:not(:empty)::before {
  margin-right: 0.25em;
}

.divider:not(:empty)::after {
  margin-left: 0.25em;
}
