.signature-capture canvas {
  position: relative;
  margin: 0;
  padding: 0;
  border: 4px solid #dfe1e2;
  width: 100%;
}

.signature-capture {
  margin-bottom: 16px;
}

.signature-capture .usa-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
