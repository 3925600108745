.form-input.phone-number .phone-input {
  padding-left: 88px;
  max-width: 100%;
}

.form-input.phone-number .country-wrapper {
  position: absolute;
}

.form-input.phone-number .country-wrapper select {
  width: 80px;
  opacity: 0;
}
.form-input.phone-number .country-wrapper select::before {
  content: '';
  cursor: pointer;
  display: inline-block;
  width: 80px;
  height: 40px;
  position: absolute;
}

.form-input.phone-number .country-wrapper .country-code-clicker {
  display: inline-block;
  position: absolute;
  margin-top: 0px;
  line-height: 40px;
  height: 40px;
  width: 80px;
  font-size: 14px;
  border: 1px solid #565c65;
  background: linear-gradient(#565c6500, #565c652b);
  cursor: pointer;
  text-align: center;
}

.form-input.license-plate .plate-input,
.form-input.vin .vin-input {
  display: inline-block;
  margin-top: 0;
  max-width: calc(100% - 64px);
  margin-right: 8px;
}

.form-input.license-plate .plate-input.no-scan,
.form-input.vin .vin-input.no-scan {
  max-width: 100%;
}

.icon-button-round {
  height: 2.5rem;
  margin: 0 !important;
  vertical-align: top;
  width: 56px;
}

.icon-button-round svg {
  scale: 1.4;
}

.gray-text {
  color: #71767a;
}

.loading-button {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.loading-button > span {
  line-height: 16px;
  height: 16px;
  display: inline-block;
}

.loading-button.loading {
  padding-right: 16px !important;
  cursor: not-allowed;
}

.loading-button:disabled {
  cursor: not-allowed;
}

.loading-button .afp-spinner[data-element='spinner'] {
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
}

.loading-button .afp-spinner[data-element='spinner'] .afp-spinner-border {
  height: 16px;
  width: 16px;
  border: none;
  background: #565c65;
}

.loading-button
  .afp-spinner[data-element='spinner']
  .afp-spinner-border
  .spinner-loading {
  width: 16px;
  height: 16px;
  text-align: center;
}

.loading-button
  .afp-spinner[data-element='spinner']
  .afp-spinner-border
  .spinner-loading::after {
  width: 12px;
  height: 12px;
  border: 2.5px solid #fff;
  border-top-color: var(--theme-color-primary-light);
  display: inline-block;
}

.loading-button .original-label {
  display: inline-block;
  line-height: 16px;
  vertical-align: middle;
}
