[tabindex]:focus {
  outline: none;
}

@media (min-width: 1400px) {
  .usa-header--extended .usa-nav__primary {
    margin-top: 8px;
  }

  .usa-nav__primary-item {
    padding: 0 24px;
  }

  .usa-nav__submenu:not([hidden]) {
    left: -14px;
    columns: 3;
    padding: 16px 32px 32px 40px;
    width: 1024px;
  }

  .usa-nav__submenu:not([hidden]) > .usa-nav__submenu-item {
    max-width: 284px;
  }

  .usa-header.usa-header--extended .usa-nav .usa-nav__secondary {
    top: -68px;
    bottom: 0;
  }
}
