.plate-destruction .plate-tile {
  background: #e7f6f8;
}

.plate-destruction .usa-checkbox {
  background: transparent;
}

.plate-destruction .usa-checkbox label {
  margin-top: 0;
}

.plate-destruction .mono {
  font-family: 'Public Sans', monospace;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #565c65;
}

.plate-destruction .trash-btn {
  padding: 0 !important;
  height: 40px;
  width: 40px;
}
.manual-entry-details {
  padding: 16px;
  background: #f9fbfd;
  border: 1px solid #48a3ff;
  border-radius: 5px;
  margin-bottom: 24px;
}
