.managed-accordion * {
  outline: none;
}

.managed-accordion .accordion-container {
  display: block;
  margin-bottom: 12px;
}

.managed-accordion .usa-accordion__button {
  outline: none !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.managed-accordion .usa-accordion__content .vehicle {
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #a9aeb1;
}

.managed-accordion .usa-accordion__content .vehicle:last-child {
  border-bottom: none;
}

.managed-accordion .usa-accordion__content .vehicle .damage {
  font-size: 0.9rem;
}

.managed-accordion .usa-accordion__content .vehicle .damage:last-child ul {
  margin-bottom: 0;
}

.managed-accordion .usa-accordion__button svg {
  vertical-align: top;
  width: 100%;
  margin-right: 8px;
  height: 100%;
}

.managed-accordion .accordion-title {
  line-height: 1em;
  display: inline-block;
  width: 100%;
}

.managed-accordion .accordion-title .icon-container {
  display: inline-block;
  width: 24px;
}

.managed-accordion .accordion-title .text-container {
  display: inline-block;
  vertical-align: top;
  width: calc(90% - 8px);
  line-height: 24px;
  margin-left: 8px;
}

.managed-accordion .usa-accordion__content {
  margin-top: 0;
}
