body {
  margin: 0;
  padding: 0;
}

.slim-width {
  width: 50%;
}

.full-width {
  width: 100%;
}

.title-smaller {
  font-size: 1.7em;
}
