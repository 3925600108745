.dynamsoft-scanner {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  background: transparent;
}

.component-barcode-scanner {
  width: 100%;
  height: 100%;
  min-height: 480px;
  background: rgba(0, 0, 0, 0.95);
  position: relative;
  resize: both;
}

.close-btn {
  padding: 4px;
  position: relative;
  z-index: 101;
  background: #fff;
  text-align: center;
}

.close-btn button {
  width: 50%;
}

.dce-bg-loading {
  display: none;
  animation: 1s linear infinite dce-rotate;
  width: 40%;
  height: 40%;
  margin: auto;
  fill: #aaa;
  margin-top: -32px;
}

.dce-bg-camera {
  display: none;
  width: 40%;
  height: 40%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}

.dce-video-container {
  margin-left: 8px;
  width: calc(100% - 16px);
  height: 100%;
  position: fixed;
  top: 0;
}

.dce-video-container video {
  height: auto !important;
  margin-top: 76px;
  border-radius: 4px;
  border: 1px solid #fff;
}

.dce-scanarea {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 76px !important;
  margin-left: 8px;
}

.dce-scanlight {
  display: none;
  width: 100%;
  height: 3%;
  position: absolute;
  animation: 3s infinite dce-scanlight;
  border-radius: 50%;
  box-shadow: 0px 0px 2vw 1px #00e5ff;
  background: #fff;
}

.div-select-container {
  position: absolute;
  left: 0;
  top: 0;
}

.dce-sel-camera {
  display: block;
}

.dce-sel-resolution {
  display: block;
  margin-top: 5px;
}

.helper-text {
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
}

@keyframes dce-rotate {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes dce-scanlight {
  from {
    top: 5%;
  }

  to {
    top: 100%;
  }
}

.dynamsoft-scanner.in-page {
  position: static;
  height: 200px;
}

.dynamsoft-scanner.in-page .dce-video-container {
  position: static;
  margin-top: -30px;
  margin-left: 0;
  width: 100%;
}

.dynamsoft-scanner.in-page .dce-video-container > * {
  position: static !important;
  border-radius: 4px;
}

.dynamsoft-scanner.in-page .dce-video-container > *:nth-child(3) {
  display: none;
}

.dynamsoft-scanner.in-page .dce-video-container > div > canvas {
  position: relative !important;
  height: 200px !important;
  top: -82px !important;
  left: -8px !important;
}

.dynamsoft-scanner.in-page .dce-video-container video {
  margin-top: 0;
  position: static !important;
  border: none;
  border-radius: 0;
}

.dynamsoft-scanner.in-page .dce-video-container > canvas {
  display: none;
}

.dynamsoft-scanner.in-page .dce-scanarea {
  top: -200px !important;
  position: relative;
  margin-left: 0;
  left: 0 !important;
  width: 100% !important;
}

.dynamsoft-scanner.in-page .component-barcode-scanner {
  position: static;
  height: 200px;
  min-height: auto;
  background: transparent;
}

.dynamsoft-scanner.in-page .component-barcode-scanner .dce-bg-camera {
  position: static;
}

.dynamsoft-scanner.in-page .component-barcode-scanner .dce-bg-loading {
  position: absolute;
  z-index: -1;
  left: calc(100% - 250px);
  top: 105px;
  display: inline-block !important;
}

.dynamsoft-scanner.in-page .dce-video-container > div > canvas:nth-child(2) {
  top: -290px !important;
}

.dynamsoft-scanner.in-page .helper-text {
  position: relative;
  top: -20%;
}

.dynamsoft-scanner .capture-icon {
  position: absolute;
  margin-top: calc(-100px - 2.25rem);
  margin-left: calc(50% - 2.25rem);
  color: #fff;
  opacity: 1;
  display: none;
}

.dynamsoft-scanner .capture-icon.show {
  display: block;
}

.dynamsoft-scanner .capture-icon.fade {
  opacity: 0;
  transition: opacity ease-out 2s;
}

.dynamsoft-scanner .scanner-spinner {
  position: absolute;
  z-index: -1;
  margin-left: calc(50% - 32px);
  margin-top: 24px;
}
