.plate-reconciliation .plate-tile {
  background: #e7f6f8;
}

.plate-reconciliation .usa-checkbox {
  background: transparent;
}

.plate-reconciliation .usa-checkbox label {
  margin-top: 0;
}

.plate-reconciliation .mono {
  font-family: 'Public Sans', monospace;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #565c65;
}

.plate-reconciliation .trash-btn {
  padding: 0 !important;
  height: 40px;
  width: 40px;
}
