.usa-breadcrumb {
  margin-bottom: 1rem;
}

.usa-breadcrumb__list-item {
  position: static;
}

.usa-breadcrumb__list-item:not(:last-child)::after {
  height: 2ex;
  position: relative;
  content: '';
  display: inline-block;
  -webkit-mask: url(/static/media/navigate_next.e49f71b6.svg) no-repeat
    center/2ex 2ex;
  mask: url(/static/media/navigate_next.e49f71b6.svg) no-repeat center/2ex 2ex;
  background-color: #71767a;
  bottom: -0.2em;
  width: 10px;
}

.pwa-breadcrumbs .afp-chip {
  padding-top: 0;
  padding-bottom: 0;
  height: 28px;
  line-height: 28px;
  min-height: 28px;
  cursor: pointer;
}

.pwa-breadcrumbs .afp-chip .afp-chip__container > label {
  max-width: 100%;
  color: #1B1B1B;
  cursor: pointer;
}
