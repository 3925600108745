.fsr-modal.mobile {
  width: 90% !important;
  margin-top: 10% !important;
}

.afp-modal-overlay:before {
  height: 0 !important;
}

.afp-modal-overlay .centered {
  text-align: center;
}

.fsr-modal .cancel {
  text-decoration: underline;
  font-size: 22px;
  line-height: 26px;
  color: #005ea2;
}

.fsr-modal .title {
  font-weight: 700;
  margin-top: 40px !important;
  font-size: 24px;
  line-height: 32px;
  color: #1b1b1b;
}

.fsr-modal .actions {
  margin-top: 32px !important;
  padding-top: 28px !important;
  height: 100px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.fsr-modal .button-label-with-span {
  font-weight: 700;
}

.fsr-modal .modal-inner {
  padding-left: 16px;
  padding-right: 36px;
  padding-bottom: 0;
}

.fsr-modal .content {
  margin-top: 12px;
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #1b1b1b;
}
