.mobile .signature-modal {
  width: 100% !important;
  margin-top: 10% !important;
}

.afp-modal-overlay {
  overflow: hidden;
}

.afp-modal-overlay:before {
  height: 0 !important;
}

.afp-modal-overlay .centered {
  text-align: center;
}

.signature-modal .cancel {
  text-decoration: underline;
  font-size: 1.13rem;
  line-height: 36px;
  color: #005ea2;
  cursor: pointer;
}

.signature-modal .title {
  font-weight: 700;
  margin-top: 8px !important;
  font-size: 24px;
  line-height: 32px;
  color: #1b1b1b;
}

.signature-modal .actions {
  margin-top: 42px !important;
  padding-top: 28px !important;
  height: 150px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.signature-modal .usa-button--primary {
  background-color: #005ea2;
}

.afp-modal-wrapper.blue-btn .actions .usa-button--primary {
  background-color: #005ea2;
}

.signature-modal .button-label-with-span {
  font-weight: 700;
}

.signature-modal .modal-inner {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0;
}

.signature-modal .content {
  margin-top: 12px;
  margin-right: 0;
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #1b1b1b;
}
