.mobile-textbox {
  max-width: 100%;
}

.usa-alert--info {
  border: 1px solid #d9e8f6;
}

.usa-alert--info h3 {
  font-size: 15px;
}

.usa-alert--info .usa-alert__body {
  font-size: 14px;
  line-height: 24px;
}

.usa-alert--info .usa-alert__body .total {
  font-size: 24px;
  font-weight: 700;
}

.usa-alert--info .usa-alert__body .usa-alert__text {
  margin-top: 16px;
}

.usa-alert--info div[data-testid='alert-message-close'] {
  display: none;
}

.icon-button {
  width: 68px;
  height: 40px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  position: relative;
  margin-left: calc(100% - 68px);
  top: -40px;
}

.icon-button svg {
  scale: 1.4;
}

.icon-input {
  border-right: none;
  width: calc(100% - 68px);
}

.form-error {
  border-left: 4px solid #b50909;
  padding-left: 20px;
  margin-top: 24px;
}

.form-error .form-error-msg {
  color: #b50909;
}

.link-container {
  display: flex;
  align-items: center;
}

.exteriors {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exteriors .usa-select {
  width: 183px;
}

.vehicle-tab-heading {
  width: max-content;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 4px solid #005ea2;
  color: #005ea2;
  font-size: 16px;
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

.evaf-title {
  line-height: 130%;
  margin: 24px 0px;
}

.continue-button-evaf-mob {
  position: relative;
}

.continue-button-evaf-desk {
  width: 100%;
}

.right-icon-evaf {
  position: absolute;
  color: white !important;
}

@media (min-width: 321px) and (max-width: 390px) {
  .right-icon-evaf {
    right: 70px !important;
  }
}

.right-icon-evaf-mob {
  bottom: 74px;
  right: 105px;
}
.right-icon-evaf-desk {
  bottom: 58px;
  right: 270px;
}

.evaf-title {
  display: grid;
  gap: 8px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  align-items: center;
}

.error {
  color: #b50909;
}
