.plate-listing * {
  outline: none;
}

.plate-listing .usa-accordion__button {
  outline: none !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.plate-listing .usa-accordion__content .vehicle {
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #a9aeb1;
}

.plate-listing .usa-accordion__content .vehicle:last-child {
  border-bottom: none;
}

.plate-listing .usa-accordion__content .vehicle .damage {
  font-size: 0.9rem;
}

.plate-listing .usa-accordion__content .vehicle .damage:last-child ul {
  margin-bottom: 0;
}

.plate-listing .usa-accordion__button svg {
  vertical-align: top;
  width: 100%;
  margin-right: 8px;
  height: 100%;
}

.plate-listing .accordion-title {
  line-height: 1em;
  display: inline-block;
  width: 100%;
}

.plate-listing .accordion-title .icon-container {
  display: inline-block;
  width: 24px;
}

.plate-listing .accordion-title .text-container {
  display: inline-block;
  vertical-align: top;
  width: calc(90% - 8px);
  line-height: 1.3rem;
  margin-left: 8px;
}

.usa-icon.green {
  fill: #008817;
}

.usa-icon.red {
  fill: #b50909;
}

.plate-listing .usa-accordion__content {
  margin-top: 0;
  padding: 8px !important;
}

.plate-listing .afp-counter-tag .afp-counter-tag__count {
  vertical-align: revert;
  line-height: 23px;
  background: #005ea2 !important;
  color: #fff !important;
}

.plate-listing .afp-counter-tag {
  margin-right: 8px;
}

.plate-listing .plate-tile {
  background: #e7f6f8;
  margin-bottom: 16px;
}

.plate-listing .missing-plate {
  margin: 0;
  padding: 0;
  color: #b50909;
  margin-left: 20px;
}

.plate-listing .trash-btn {
  padding: 0 !important;
  height: 40px;
  width: 40px;
}

.plate-listing.empty .usa-accordion__content {
  display: none !important;
}
