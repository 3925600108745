.photo-capture input[type='file'] {
  display: none;
}

.photo-capture .photo-item {
  background: #d9e8f6;
  padding: 8px;
  margin-bottom: 16px;
}

.photo-capture .damage-photo {
  max-height: 46px;
  max-width: 64px;
  border: 1px solid #a9aeb1;
}

.photo-capture .file-name span {
  display: inline-block;
  vertical-align: top;
}

.photo-capture .file-name span:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 110px;
  overflow: hidden;
}

.photo-capture .delete-button {
  padding: 0 !important;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: inset 0 0 0 2px #005ea2;
  color: #005ea2;
  text-align: center;
  border-radius: 0.25rem;
  display: inline-block;
}

.photo-capture .delete-button svg {
  scale: 1.4;
}

.photo-deleter {
  background: #fffbe8;
  color: #565c65;
  padding: 16px;
  border: 1px solid #dfe1e2;
  font-size: 14px;
  line-height: 21px;
  position: absolute;
  left: 40px;
  width: calc(100% - 81px);
  margin-top: -100px;
  height: 90px;
}

.photo-deleter button {
  height: 28px;
  width: 100%;
}

.photo-deleter::after {
  content: '';
  border: none !important;
  background: #fffbe8 !important;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  border-right: 1px solid #dfe1e2 !important;
  border-bottom: 1px solid #dfe1e2 !important;
  display: inline-block;
  margin-top: 11px;
  margin-left: 94%;
}

.damage-code.info {
  color: #565c65;
}

.damage-code {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  margin: 0;
}
