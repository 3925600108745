.row-sub .field-border {
  border-bottom: 1px solid #add2f0;
}

.row-sub .inline-block {
  display: inline-block;
}

.row-sub .inline-half {
  margin-left: 24px;
  width: calc(50% - 12px);
  vertical-align: top;
}

.row-sub .inline-60 {
  margin-left: 24px;
  width: calc(60% - 12px);
  vertical-align: top;
}

.row-sub .inline-40 {
  margin-left: 24px;
  width: calc(40% - 12px);
  vertical-align: top;
}

.row-sub .inline-60:first-child {
  margin-left: 0px;
}

.row-sub .inline-40:first-child {
  margin-left: 0px;
}

.row-sub .inline-half:first-child {
  margin-left: 0px;
}

.afp-table-container--scrollable {
  overflow-x: hidden !important;

  .usa-table {
    text-align: center !important;
  }
}
.afp-responsive-table tbody tr th,
.afp-responsive-table tbody tr td {
  padding: 0.2rem !important;
  vertical-align: middle;
}
