@import '~@progress/kendo-theme-default/scss/scheduler/_index.scss';

$fleet-blue: #005ea2;

.k-scheduler {
  font-family: Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol !important;
}

.k-button-solid-base {
  &.k-selected {
    background-color: $fleet-blue;
    border-color: $fleet-blue;
  }
}

.k-event {
  background-color: $fleet-blue;
}
