.damage-in-transit h2.heading {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #005ea2;
}

.damage-in-transit .nowrap {
  white-space: nowrap;
}

.damage-accordion * {
  outline: none;
}

.damage-accordion .usa-accordion__button {
  outline: none !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.damage-accordion .usa-accordion__content .vehicle {
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid #a9aeb1;
}

.damage-accordion .usa-accordion__content .vehicle:last-child {
  border-bottom: none;
}

.damage-accordion .usa-accordion__content .vehicle .damage {
  font-size: 0.9rem;
}

.damage-accordion .usa-accordion__content .vehicle .damage:last-child ul {
  margin-bottom: 0;
}

.damage-accordion .usa-accordion__button svg {
  vertical-align: top;
  width: 100%;
  margin-right: 8px;
  height: 100%;
}

.damage-accordion .accordion-title {
  line-height: 1em;
  display: inline-block;
  width: 100%;
}

.damage-accordion .accordion-title .icon-container {
  display: inline-block;
  width: 24px;
}

.damage-accordion .accordion-title .text-container {
  display: inline-block;
  vertical-align: top;
  width: calc(90% - 8px);
  line-height: 24px;
  margin-left: 8px;
}

.usa-icon.green {
  fill: #008817;
}

.usa-icon.red {
  fill: #b50909;
}

.damage-accordion .usa-accordion__content {
  margin-top: 0;
}

.damage-in-transit .damage-details label {
  margin-top: 0 !important;
}

.damage-in-transit .damage-details {
  padding: 16px;
  background: #f9fbfd;
  border: 1px solid #48a3ff;
  border-radius: 5px;
  margin-bottom: 24px;
}

.damage-in-transit .count-bubble {
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  background: #005ea2;
  color: #ffffff;
  height: 24px;
  width: 24px;
  text-align: center;
  border-radius: 50%;
  margin-left: 8px;
  line-height: 24px;
  vertical-align: top;
  padding-top: 1px;
}

.damage-in-transit .textbox-note {
  color: #71767a;
  margin-top: 4px;
  line-height: 24px;
}

.damage-in-transit .error {
  color: #b50909;
}

.form-error {
  border-left: 4px solid #b50909;
  padding-left: 20px;
  margin-top: 24px;
}

.form-error .form-error-msg {
  color: #b50909;
}
.damage-code-info {
  margin: 0;
  color: #565c65;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
}

.damage-code-checks {
  margin: 14px 0 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.damage-code-chip {
  margin: 10px 0px;
  .afp-chip {
    margin: 5px;
    position: relative;
    padding-right: 35px;
  }
}

.damage-code-chipclose {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: calc(1.125rem / 2);
  color: #fff;
  background: var(--theme-color-primary);
}
